import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Snapcard3 = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          profile: file(relativePath: { eq: "projects/snapcard/snapcard-mobile-3.png" }) {
            childImageSharp {
              fluid(maxHeight: 520, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <Img className="w-40 xl:w-64 lg:w-64" objectFit="cover" fluid={data.profile.childImageSharp.fluid} />
        </div>
      )}
    />
  );
};

export default Snapcard3;
