import * as React from 'react';
import PropTypes from 'prop-types';

const RowImageContainer = ({ children }) => {
  return <div className="flex flex-row justify-between">{children}</div>;
};

RowImageContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RowImageContainer;
