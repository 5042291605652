import React from 'react';
import PropTypes from 'prop-types';
import TechnologyImage from './technology-image';

const TechnologyItem = ({ imgTag, technologyName }) => {
  return (
    <div className="w-24 h-24 rounded-md bg-gray-100 flex flex-col justify-center items-center mt-2">
      <div className="bg-white w-9 h-9 rounded-full flex justify-center items-center text-center">
        <TechnologyImage imgTag={imgTag} />
      </div>
      <span className="pt-2 font-semibold text-xs text-center">{technologyName}</span>
    </div>
  );
};

TechnologyItem.propTypes = {
  imgTag: PropTypes.string.isRequired,
  technologyName: PropTypes.string.isRequired,
};

export default TechnologyItem;
