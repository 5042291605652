import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Yarn = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          yarn: file(relativePath: { eq: "projects/tech/yarn.png" }) {
            childImageSharp {
              fixed(width: 36, height: 36) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => <Img objectFit="cover" fixed={data.yarn.childImageSharp.fixed} />}
    />
  );
};

export default Yarn;
