import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Storage = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          storage: file(relativePath: { eq: "projects/tech/googleStorage.png" }) {
            childImageSharp {
              fixed(width: 24) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={data => <Img objectFit="cover" fixed={data.storage.childImageSharp.fixed} />}
    />
  );
};

export default Storage;
