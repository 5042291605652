import React from 'react';

import Analytics from '../../images/tech/analytics';
import Amplify from '../../images/tech/aws-amplify';
import Abstract from '../../images/tech/abstract';
import ClickUp from '../../images/tech/clickup';
import Figma from '../../images/tech/figma';
import Gatsby from '../../images/tech/gatsby';
import Git from '../../images/tech/git';
import Github from '../../images/tech/github';
import GraphQL from '../../images/tech/graphql';
import Miro from '../../images/tech/miro';
import MongoDB from '../../images/tech/mongodb';
import NodeJS from '../../images/tech/nodejs';
import Postman from '../../images/tech/postman';
import Slack from '../../images/tech/slack';
import Swagger from '../../images/tech/swagger';
import TailwindCSS from '../../images/tech/tailwindcss';
import VSCode from '../../images/tech/vscode';
import Yarn from '../../images/tech/yarn';
import Swift from '../../images/tech/swift';
import Django from '../../images/tech/django';
import ReactNative from '../../images/tech/reactNative';
import PostgreSQL from '../../images/tech/postgresql';
import ElasticSearch from '../../images/tech/elastic-search';
import Ngnix from '../../images/tech/nginx';
import Redis from '../../images/tech/redis';
import DigitalOcean from '../../images/tech/digitalOcean';
import Docker from '../../images/tech/docker';
import JavaScript from '../../images/tech/javascript';
import AWSS3 from '../../images/tech/aws-s3';
import AWSEC2 from '../../images/tech/aws-ec2';
import AWSCloudWatch from '../../images/tech/aws-cloudwatch';
import PyCharm from '../../images/tech/pycharm';
import Firebase from '../../images/tech/firebase';
import Next from '../../images/tech/next';
import GoogleAppEngine from '../../images/tech/app-engine';
import GoogleStorage from '../../images/tech/storage';

const TechnologyImage = ({ imgTag }) => {
  const components = {
    analytics: Analytics,
    amplify: Amplify,
    clickup: ClickUp,
    figma: Figma,
    gatsby: Gatsby,
    git: Git,
    github: Github,
    graphql: GraphQL,
    miro: Miro,
    abstract: Abstract,
    mongodb: MongoDB,
    nodejs: NodeJS,
    postman: Postman,
    slack: Slack,
    swagger: Swagger,
    tailwindcss: TailwindCSS,
    vscode: VSCode,
    yarn: Yarn,
    swift: Swift,
    django: Django,
    reactnative: ReactNative,
    postgresql: PostgreSQL,
    elasticsearch: ElasticSearch,
    ngnix: Ngnix,
    redis: Redis,
    digitalocean: DigitalOcean,
    docker: Docker,
    js: JavaScript,
    awss3: AWSS3,
    awsec2: AWSEC2,
    awscloudwatch: AWSCloudWatch,
    pycharm: PyCharm,
    firebase: Firebase,
    next: Next,
    appengine: GoogleAppEngine,
    storage: GoogleStorage,
  };

  const Item = components[imgTag.toLowerCase() || 'Gatsby'];

  return <Item />;
};
export default TechnologyImage;
