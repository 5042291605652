import * as React from 'react';
import PropTypes from 'prop-types';

import H1 from '../../../components/core/h1';

const ProjectTitle = ({ name, description }) => {
  return (
    <div className="text-center">
      <H1>{name}</H1>
      <p className="font-bold text-sm text-gray-700 pt-2">{description}</p>
    </div>
  );
};

ProjectTitle.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ProjectTitle;
