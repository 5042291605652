import * as React from 'react';
import PropTypes from 'prop-types';

const TechStackItemContainer = ({ children, stackName, isFirstItem }) => {
  const pt = isFirstItem ? '' : 'pt-4';
  return (
    <li className={pt}>
      <p className="font-bold leading-16">{stackName}</p>
      <div className="flex flex-wrap gap-4">{children}</div>
    </li>
  );
};

TechStackItemContainer.propTypes = {
  children: PropTypes.node.isRequired,
  stackName: PropTypes.string.isRequired,
};

export default TechStackItemContainer;
