import * as React from 'react';
import PropTypes from 'prop-types';

const H1 = ({ children }) => {
  return (
    <>
      <h1 className="font-bold text-4xl xl:text-6xl lg:text-6xl md:text-6xl">{children}</h1>
    </>
  );
};

H1.propTypes = {
  children: PropTypes.node.isRequired,
};

export default H1;
