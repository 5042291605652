import * as React from 'react';
import PropTypes from 'prop-types';

const TechStackItemContainer = ({ children }) => {
  return <ul className="text-sm text-gray-700 pt-4">{children}</ul>;
};

TechStackItemContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TechStackItemContainer;
