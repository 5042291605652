import React from 'react';

import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const SnapcardBusiness = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          profile: file(relativePath: { eq: "projects/snapcard/snapcard-business-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      `}
      render={data => <Img objectFit="cover" fluid={data.profile.childImageSharp.fluid} />}
    />
  );
};

export default SnapcardBusiness;
